import { defineStore, acceptHMRUpdate } from 'pinia';
import handleFrontendError from '../utils/handleFrontendError';
import type { Image, RatingIcon, Theme } from '~/types/app.types';

export const useThemeStore = defineStore('Theme', () => {
	const baseURL = useRuntimeConfig().public.baseUrl;
	const logo = ref(
		'https://assets.weedys.com/storage/v1/object/public/Images/weedys-2024-logotype.png',
	);

	const ratingIconFull = ref<RatingIcon>({
		name: 'full',
		url: 'https://assets.weedys.com/storage/v1/object/public/Images/weedys-rating-star-icons_full.svg',
		locationId: 'rating-icon-full',
	});
	const ratingIconHalf = ref<RatingIcon>({
		name: 'half',
		url: 'https://assets.weedys.com/storage/v1/object/public/Images/weedys-rating-star-icons_half.svg',
		locationId: 'rating-icon-half',
	});
	const ratingIconEmpty = ref<RatingIcon>({
		name: 'empty',
		url: 'https://assets.weedys.com/storage/v1/object/public/Images/weedys-rating-star-icons_empty.svg',
		locationId: 'rating-icon-empty',
	});
	const cartIcon = ref<Image | null>(null);
	const settings = ref<Theme>({
		headerAccentColor: '#000000',
		headerColor: '#171717',
		footerColor: '#000000',
		noticeBarActive: false,
		noticeBarType: 'Announcement',
		noticeBarMessage: '',
	});
	const headerImage = ref('');
	const footerImage = ref('');

	async function fetchRatingIcons() {
		try {
			const response = await $fetch<RatingIcon[]>('/api/theme/rating-icons', {
				baseURL,
				cache: 'no-cache',
			});

			ratingIconFull.value = response.filter((icon) => icon.name === 'full')[0];
			ratingIconHalf.value = response.filter((icon) => icon.name === 'half')[0];
			ratingIconEmpty.value = response.filter((icon) => icon.name === 'empty')[0];
		} catch (error) {
			handleFrontendError(error, false);
		}
	}

	async function fetchLogo() {
		try {
			const response = await $fetch<{ images: Image[] }>('/api/images/location/header-logo', {
				baseURL,
				cache: 'no-cache',
			});

			if (response?.images[0].url) {
				logo.value = response.images[0].url;
			}
		} catch (error) {
			handleFrontendError(error, false);
		}
	}

	async function fetchSettings() {
		try {
			const response = await $fetch<Theme>('/api/theme/settings', {
				baseURL,
				cache: 'no-cache',
			});

			settings.value = response;
		} catch (error) {
			handleFrontendError(error, false);
		}
	}

	async function fetchImages() {
		try {
			const header = await $fetch<{ images: Image[] }>(
				'/api/images/location/header-background',
				{ baseURL, cache: 'no-cache' },
			);
			const footer = await $fetch<{ images: Image[] }>(
				'/api/images/location/footer-background',
				{ baseURL, cache: 'no-cache' },
			);

			if (header.images.length) {
				headerImage.value = header.images[0].url;
			}
			if (footer.images.length) {
				footerImage.value = footer.images[0].url;
			}
		} catch (error) {
			handleFrontendError(error, false);
		}
	}

	async function fetchCartIcon() {
		try {
			const response = await $fetch<{ images: Image[] }>('/api/images/location/cart-icon', {
				baseURL,
				cache: 'no-cache',
			});
			if (!response.images?.length) return;
			cartIcon.value = response.images[0];
		} catch (error) {
			handleFrontendError(error, false);
		}
	}

	async function init() {
		fetchLogo();
		fetchSettings();
		fetchImages();
		fetchRatingIcons();
		fetchCartIcon();
	}

	return {
		cartIcon,
		init,
		logo,
		ratingIconFull,
		ratingIconHalf,
		ratingIconEmpty,
		settings,
		headerImage,
		footerImage,
	};
});

if (import.meta.hot) {
	import.meta.hot.accept(acceptHMRUpdate(useThemeStore, import.meta.hot));
}
